<template>
  <a
    :id="id"
    :class="classes"
    :href="link"
    class="btn"
    target="_blank"
    title="Formulaire de don Fondation du Souffle (ouverture dans un nouvel onglet)"
  >
    {{ text }}
    <span aria-hidden="true" class="i-a11y-content"></span>
  </a>
</template>

<script>
export default {
  name: 'BtnGift',
  props: {
    text: {
      type: String,
      default: 'Faire un don'
    },
    classes: {
      type: String
    },
    link: {
      type: String
    },
    id: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.i-a11y-content {
  font-size: $fs-txt-C;

  @include respond-to('xs up') {
    font-size: $fs-title-F;
  }
}
</style>
