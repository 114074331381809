import { ref } from 'vue'
import data from '@/data/content.json'

export const video = ref({
  isOpen: false,
  setIsOpen(value) {
    this.isOpen = value
  }
})

export const taxReduction = {
  percentage: data.tax_reduce.percentage,
  getReduceAmount(value) {
    return Math.round(value * (this.percentage / 100))
  }
}

export const isInViewport = (element, entirely = true) => {
  const rect = element.getBoundingClientRect()

  let isVisible = entirely
    ? rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    : rect.top < window.innerHeight - rect.height / 2

  return isVisible
}
