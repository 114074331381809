<template>
  <section class="heading">
    <div class="layout-content heading-wrapper">
      <div class="heading-content">
        <div class="heading-title-wrapper">
          <h1 class="heading-title" v-html="value"></h1>

          <img :src="require(`@/assets/images/shape.svg`)" alt="" />
        </div>

        <a href="#story" class="heading-link i-a11y i-after i-inline i-arrow">
          {{ link }}
          <span aria-hidden="true" class="i-a11y-content"></span>
        </a>
      </div>

      <div class="heading-form">
        <FormGift />
      </div>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import FormGift from '@/components/ui/FormGift.vue'

export default {
  name: 'Heading',
  components: {
    FormGift
  },
  data() {
    return {
      value: data.heading.value,
      link: data.heading.link
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  position: relative;
  display: flex;
  min-height: 81rem;
  background-image: url('@/assets/images/bg-heading.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 35% 50%;

  @include respond-to('medium up') {
    background-position: center;
  }

  &::before {
    @include absolute(top right bottom left);

    content: '';
    background: linear-gradient(
      41deg,
      rgba($c-black, 0.5) 18.33%,
      rgba($c-black, 0) 78.01%
    );
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gutter-D;
    width: 100%;

    @include respond-to('medium up') {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &-content {
    position: relative;
    z-index: 2;
    max-width: 42rem;
    margin: 30rem 0 0;

    @include respond-to('medium') {
      text-align: center;
    }

    @include respond-to('medium up') {
      max-width: 45rem;
      margin: 0 0 12rem;
      flex-shrink: 0;
    }

    @include respond-to('large up') {
      max-width: 70rem;
    }

    img {
      @include absolute(bottom -3rem left 25rem);

      width: 18rem;
      pointer-events: none;

      @include respond-to('medium') {
        display: none;
      }
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    margin-bottom: $gutter-B;
    color: $c-white;
    font-size: 3.8rem;
    text-shadow: 0 3px 4px $c-black;
    line-height: 1.25;

    @include respond-to('medium up') {
      margin-bottom: $gutter-E;
      font-size: $fs-title-B;
    }

    &-wrapper {
      position: relative;
    }
  }

  &-link {
    color: $c-white;
    font-size: $fs-txt-B;
    font-weight: 700;
    text-shadow: 0 3px 4px $c-black;
    text-transform: uppercase;

    @include underline(
      right,
      $c-white,
      $y: 100%,
      $icon: ('size': 3.4rem, 'top': 6px)
    );

    @include respond-to('medium up') {
      font-size: $fs-title-H;
    }

    &.i-after.i-inline {
      .i-a11y-content {
        margin-left: 0.8rem;

        &::before {
          font-size: 3.2rem;
        }
      }
    }
  }

  &-form {
    position: relative;
    z-index: 2;
    margin-bottom: 6rem;

    @include respond-to('xs up') {
      margin-bottom: 10rem;
    }
  }
}
</style>
