<template>
  <section class="keyfigure">
    <h2 class="keyfigure-title">
      <span v-for="(text, index) in title" :key="index">
        {{ text }}
      </span>
    </h2>

    <div class="keyfigure-content layout-content">
      <Carousel
        :breakpoints="breakpoints"
        :i18n="ariaLabels"
        :items-to-show="itemsToShow"
      >
        <Slide
          v-for="(KeyFigure, index) in KeyFigures"
          :key="index"
          :class="[
            index % 2 === 0 ? 'keyfigure-item-even' : 'keyfigure-item-odd'
          ]"
          class="keyfigure-item"
        >
          <ItemKeyFigure :content="KeyFigure" />
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>

    <div class="layout-content">
      <div class="keyfigure-highlight">
        <p>{{ highlight }}</p>
      </div>

      <div class="keyfigure-info">
        <p v-html="info"></p>
      </div>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import ItemKeyFigure from '@/components/ui/ItemKeyFigure.vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'

export default {
  name: 'KeyFigure',
  components: {
    ItemKeyFigure,
    Carousel,
    Slide,
    Pagination
  },
  data() {
    return {
      title: data.keyfigure.title,
      KeyFigures: data.KeyFigure,
      highlight: data.keyfigure.highlight,
      info: data.keyfigure.info,
      itemsToShow: 1,
      breakpoints: {
        961: {
          itemsToShow: 4,
          mouseDrag: false,
          touchDrag: false
        }
      },
      ariaLabels: data.carousel.ariaLabels
    }
  }
}
</script>

<style lang="scss" scoped>
.keyfigure {
  min-height: 103rem;
  padding-top: 9rem;
  margin-bottom: $gutter-H;
  background-color: $c-white;
  background-image: url('@/assets/images/bg-keyfigure.png');
  background-repeat: no-repeat;
  background-position-y: 23rem;

  @include respond-to('small') {
    background-position-x: -20rem;
  }

  @include respond-to('small up') {
    margin-bottom: 0;
    min-height: 108rem;
    background-position-y: 16rem;
  }

  @include respond-to('medium up') {
    background-position-y: 21rem;
  }

  &-title {
    margin-bottom: 8rem;
    text-align: center;

    @include respond-to('medium') {
      padding-inline: $gutter-D;
    }

    @include respond-to('medium up') {
      margin-bottom: $gutter-H;
    }

    span {
      &:last-child {
        font-weight: 400;
      }
    }
  }

  &-highlight {
    display: flex;
    justify-content: center;
    margin-top: $gutter-B;

    @include respond-to('medium') {
      margin-inline: 2.2rem;
    }

    p {
      padding: $gutter-A $gutter-C;
      color: $c-primary-A;
      font-size: $fs-title-G;
      font-weight: 700;
      text-align: center;
      background-color: rgba($c-white, 0.5);
      border-radius: $radius-D;

      @include respond-to('medium up') {
        font-size: $fs-title-F;
      }
    }
  }

  &-info {
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: 18rem;
    color: $c-primary-A;
    font-size: $fs-txt-D;
    font-style: italic;

    @include respond-to('small up') {
      top: 19rem;
      font-size: $fs-txt-C;
    }

    @include respond-to('small to medium') {
      top: 26rem;
    }

    p {
      text-align: right;

      @include respond-to('medium') {
        max-width: 80%;
      }
    }
  }
}
</style>
