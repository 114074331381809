<template>
  <section class="social-share">
    <div class="social-share-wrapper layout-content">
      <div class="social-share-media">
        <img
          :height="imgHeight"
          :src="require(`@/assets/images/${img}`)"
          :width="imgWidth"
          alt=""
        />
      </div>

      <div class="social-share-content">
        <h2 class="social-share-title" v-html="title"></h2>
        <Share />
      </div>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import Share from '@/components/ui/Share.vue'

export default {
  name: 'SocialShare',
  components: {
    Share
  },
  data() {
    return {
      title: data.social_share.title,
      img: data.social_share.image.file,
      imgWidth: data.social_share.image.width,
      imgHeight: data.social_share.image.height
    }
  }
}
</script>

<style lang="scss" scoped>
.social-share {
  position: relative;
  overflow: hidden;
  background-color: $c-bg-C;

  &-wrapper {
    display: flex;
    flex-direction: column;
    padding-block: $gutter-H;

    @include respond-to('medium') {
      gap: $gutter-D;
    }

    @include respond-to('medium up') {
      flex-direction: row;
      align-items: center;
      min-height: 38.8rem;
    }
  }

  &-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: $gutter-E $gutter-G;
    background-color: $c-white;
    border-radius: $radius-B;
    text-align: center;

    @include respond-to('medium up') {
      max-width: 45rem;
      padding: $gutter-I $gutter-E;
    }

    @include respond-to('large up') {
      max-width: 58.8rem;
    }
  }

  &-title {
    font-size: $fs-title-F;

    @include respond-to('medium') {
      font-size: $fs-txt-A;
      font-weight: 600;
    }
  }

  &-media {
    @include respond-to('medium up') {
      @include absolute(top 4.8rem right 6rem);
    }

    img {
      @include respond-to('medium') {
        width: 100%;
      }
    }
  }
}
</style>
