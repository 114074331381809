<template>
  <div class="story-quote">
    <blockquote>
      <p class="story-quote-text">
        <span class="story-quote-icon i-a11y i-after i-inline-flex i-quote">
          <span class="i-a11y-content" aria-hidden="true"></span>
        </span>

        {{ quote }}

        <span class="story-quote-icon i-a11y i-after i-inline-flex i-quote">
          <span class="i-a11y-content" aria-hidden="true"></span>
        </span>
      </p>

      <p class="story-quote-author">{{ author }}</p>
    </blockquote>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'StoryQuote',
  props: {
    quote: {
      type: String
    },
    author: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
.story-quote {
  display: flex;
  flex-direction: column;
  gap: $gutter-G;
  background-color: $c-bg-F;
  padding: $gutter-E $gutter-H $gutter-I;
  color: $c-primary-A;
  border-radius: $radius-B;

  @include respond-to('medium up') {
    padding: $gutter-J 12rem $gutter-J 8.8rem;
  }

  blockquote {
    margin: 0;
  }

  &-text {
    position: relative;
    font-style: italic;
  }

  &-icon {
    @include absolute(top 4px left (-$gutter-F));

    &:last-child {
      top: auto;
      left: auto;
      right: -$gutter-F;
      bottom: 4px;
      transform: rotate(180deg);
    }

    .i-a11y-content {
      &::before {
        color: $c-secondary-A;
      }
    }
  }

  &-author {
    margin-top: $gutter-B;
    font-weight: 700;
  }
}
</style>
