<template>
  <div class="collapsible" :class="{ 'is-expanded': isExpanded }">
    <button
      type="button"
      class="collapsible-cta"
      :class="classes"
      aria-controls="collapsible"
      :aria-expanded="isExpanded"
      @click="isExpanded = !isExpanded"
    >
      {{ label }}

      <span class="i-a11y-content" aria-hidden="true"></span>
    </button>

    <div id="collapsible" class="collapsible-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapsible',
  props: {
    expanded: {
      type: Boolean,
      default: true
    },
    classes: {
      type: String
    },
    label: {
      type: String
    }
  },
  data() {
    return {
      isExpanded: this.expanded
    }
  }
}
</script>

<style scoped lang="scss">
.collapsible {
  $this: &;
  $is-expanded: '#{$this}.is-expanded > &';

  &-cta {
    @extend %reset-btn;

    transition: $t-default;
  }

  &-content {
    display: none;
    transition: $t-default;

    #{$is-expanded} {
      display: block;
    }
  }
}
</style>
