<template>
  <section ref="missionSection" class="mission">
    <div class="mission-wrapper">
      <div ref="missionContent" class="mission-content layout-content">
        <div class="mission-heading">
          <h2 class="mission-title" v-html="title"></h2>
        </div>

        <Carousel
          id="carousel-mission"
          v-model="currentSlide"
          :i18n="ariaLabels"
          :items-to-show="itemsToShow"
          :wrap-around="false"
        >
          <Slide v-for="(mission, index) in missions" :key="index">
            <ItemMission :content="mission" />
          </Slide>
        </Carousel>

        <Carousel
          id="thumbnails"
          ref="carousel"
          v-model="currentSlide"
          :items-to-show="2"
          :mouseDrag="false"
          :touchDrag="false"
          :wrap-around="false"
        >
          <Slide v-for="index in 2" :key="index">
            <div class="carousel__slide-nav" @click="slideTo(index - 1)">
              <img
                :src="require('@/assets/images/mission' + index + '.png')"
                alt=""
                height="77"
                width="178"
              />
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import ItemMission from '@/components/ui/ItemMission.vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

export default {
  name: 'Mission',
  components: {
    ItemMission,
    Carousel,
    Slide,
    Navigation,
    Pagination
  },
  data() {
    return {
      title: data.mission.title,
      missions: data.missions,
      itemsToShow: 1,
      currentSlide: 0,
      ariaLabels: data.carousel.ariaLabels
    }
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val
    }
  }
}
</script>

<style lang="scss" scoped>
.mission {
  position: relative;
  z-index: 1;
  padding-top: 3rem;
  background-image: url('@/assets/images/deco-mission.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top left;

  @include respond-to('xs up') {
    padding-top: 8rem;
  }

  @include respond-to('medium up') {
    margin-top: 3rem;
    padding-top: 12rem;
  }

  &-content {
    padding-top: $gutter-F;
    padding-bottom: $gutter-F;

    @include respond-to('medium up') {
      padding-top: $gutter-H;
      padding-bottom: $gutter-H;
    }
  }

  &-heading {
    margin-bottom: $gutter-F;
    text-align: center;

    @include respond-to('medium up') {
      margin-bottom: $gutter-I;
    }
  }

  &-title {
    margin-bottom: $gutter-B;
    color: $c-white;
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.9rem;

    @include respond-to('medium up') {
      font-size: $fs-title-B;
      line-height: normal;
    }
  }

  &-subtitle {
    color: $c-white;
    font-size: $fs-txt-A;

    @include respond-to('medium up') {
      font-size: $fs-title-F;
    }
  }
}

#carousel-mission {
  margin-bottom: $gutter-G;
}

#thumbnails {
  max-width: 61rem;
  margin: 0 auto;
}
</style>
