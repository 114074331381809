<template>
  <div id="story" class="story">
    <h2 class="story-title">{{ title }}</h2>

    <div class="story-wrapper layout-content">
      <div class="story-media">
        <img
          :height="imgHeight"
          :src="require(`@/assets/images/${img}`)"
          :width="imgWidth"
          alt=""
        />
      </div>

      <div class="story-content">
        <StoryQuote :quote="quote" :author="author">
          <Collapsible
            :expanded="false"
            classes="i-a11y i-after i-inline-flex i-plus"
            :label="label"
          >
            <p class="story-testimony" v-html="text"></p>
            <p class="story-highlight">
              <span>{{ highlight }}</span>
            </p>
          </Collapsible>
        </StoryQuote>
      </div>
    </div>
  </div>
</template>

<script>
import data from '@/data/content.json'
import StoryQuote from '@/components/ui/StoryQuote.vue'
import Collapsible from '@/components/ui/Collapsible.vue'
export default {
  name: 'Story',
  components: {
    StoryQuote,
    Collapsible
  },
  data() {
    return {
      title: data.story.title,
      img: data.story.image.file,
      imgWidth: data.story.image.width,
      imgHeight: data.story.image.height,
      quote: data.story.quote,
      author: data.story.author,
      label: data.story.label,
      text: data.story.text,
      highlight: data.story.highlight
    }
  }
}
</script>

<style scoped lang="scss">
.story {
  padding-top: $gutter-G;

  &-title {
    margin-bottom: $gutter-G;
    text-align: center;
  }

  &-wrapper {
    position: relative;

    @include respond-to('medium') {
      display: flex;
      flex-direction: column;
      gap: $gutter-H;
    }
  }

  &-media {
    position: relative;

    @include respond-to('medium up') {
      @include absolute(top 6.7rem);

      right: 3rem;
    }

    &::before,
    &::after {
      @extend %shape;

      width: 10rem;
      height: 3.5rem;

      @include respond-to('small up') {
        width: 18rem;
        height: 6.7rem;
      }
    }

    &::before {
      @include absolute(top -1.5rem left 1.5rem);

      transform: rotate(165deg);
    }

    &::after {
      @include absolute(bottom -2.5rem right 3.5rem);

      transform: rotate(-2deg);

      @include respond-to('small up') {
        bottom: -3.5rem;
      }
    }

    img {
      width: 100%;
    }
  }

  &-content {
    @include respond-to('medium up') {
      max-width: 45rem;
    }

    @media screen and (min-width: px-to-em(1280px)) {
      max-width: 69rem;
    }
  }

  &-highlight {
    margin-top: $gutter-E;

    span {
      position: relative;
      font-weight: 700;

      &::after {
        @extend %shape;
        @include absolute(right -7rem bottom -2.5rem);

        width: 8.8rem;
        height: 3.2rem;
      }
    }
  }

  :deep(.collapsible-cta) {
    @include underline(
      center,
      $change-text-color: false,
      $y: 100%,
      $icon: ('size': 1.8rem, 'top': 0)
    );

    display: flex;
    color: $c-line-A;
    font-size: $fs-title-H;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 3rem;
  }

  :deep(.collapsible-cta[aria-expanded='true']) {
    .i-a11y-content {
      &::before {
        content: $i-minus;
      }
    }
  }

  :deep(.collapsible-content) {
    padding-top: $gutter-F;
  }
}
</style>
