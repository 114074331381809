<template>
  <teleport to="head">
    <meta
      v-for="(metaTag, index) in metaData.default"
      :key="index"
      :content="metaTag.content"
      :name="metaTag.name"
    />

    <meta
      v-for="(metaTag, index) in metaData.openGraph"
      :key="index"
      :content="metaTag.content"
      :property="'og:' + metaTag.name"
    />

    <meta
      v-for="(metaTag, index) in metaData.twitter"
      :key="index"
      :content="metaTag.content"
      :property="'twitter:' + metaTag.name"
    />
  </teleport>
</template>

<script>
import data from '@/data/content.json'

export default {
  name: 'MetaTags',
  data() {
    return {
      title: data.metadata.title,
      desc: data.metadata.description,
      url: data.metadata.url,
      img: require('@/assets/images/' + data.metadata.image),
      metaData: {}
    }
  },
  created() {
    this.metaData = {
      default: [
        {
          name: 'title',
          content: this.title
        },
        {
          name: 'description',
          content: this.desc
        },
        {
          name: 'robots',
          content: 'index,follow'
        }
      ],
      openGraph: [
        {
          name: 'title',
          content: this.title
        },
        {
          name: 'description',
          content: this.desc
        },
        {
          name: 'url',
          content: this.url
        },
        {
          name: 'image',
          content: this.img
        }
      ],
      twitter: [
        {
          name: 'title',
          content: this.title
        },
        {
          name: 'description',
          content: this.desc
        },
        {
          name: 'image',
          content: this.img
        }
      ]
    }
  }
}
</script>
