<template>
  <MetaTags />
  <Header />
  <Main />
  <Footer />
</template>

<script>
import MetaTags from '@/components/MetaTags'
import Header from '@/components/layout/Header'
import Main from '@/components/layout/Main'
import Footer from '@/components/layout/Footer'

export default {
  name: 'App',
  components: {
    MetaTags,
    Header,
    Main,
    Footer
  }
}
</script>
