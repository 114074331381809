<template>
  <main class="main-content layout-wrapper" role="main">
    <Heading />
    <Donation />
    <Mission />
    <KeyFigure />
    <Story />
    <GiftTax />
    <SocialShare />
  </main>
</template>

<script>
import Heading from '@/components/section/Heading.vue'
import Mission from '@/components/section/Mission.vue'
import Donation from '@/components/section/Donation.vue'
import KeyFigure from '@/components/section/KeyFigure.vue'
import GiftTax from '@/components/section/GiftTax.vue'
import SocialShare from '@/components/section/SocialShare.vue'
import Story from '@/components/section/Story.vue'

export default {
  name: 'Main',
  components: {
    Heading,
    Mission,
    Donation,
    KeyFigure,
    Story,
    GiftTax,
    SocialShare
  }
}
</script>
