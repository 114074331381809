<template>
  <footer class="footer layout-wrapper" role="contentinfo">
    <div class="footer-wrapper layout-content">
      <div class="footer-top">
        <div class="footer-text">
          <div class="footer-text-wrapper">
            <h2 v-html="title1"></h2>
            <p v-html="text1"></p>
            <a :href="url" :title="link + ' (nouvel onglet)'" target="_blank">{{
              link
            }}</a>
          </div>

          <div class="footer-text-wrapper">
            <h2 v-html="title2"></h2>
            <p v-html="text2"></p>
          </div>
        </div>

        <div class="footer-branding">
          <div class="footer-social">
            <Follow />
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <ul>
          <li v-for="(item, index) in menu" :key="index">
            <a
              :href="item.url"
              :title="item.link + ' (nouvel onglet)'"
              target="_blank"
              >{{ item.link }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import data from '@/data/content.json'
import Follow from '@/components/ui/Follow.vue'

export default {
  name: 'Footer',
  components: {
    Follow
  },
  data() {
    return {
      title1: data.footer.title1,
      text1: data.footer.text1,
      link: data.footer.link,
      url: data.footer.url,
      title2: data.footer.title2,
      text2: data.footer.text2,
      menu: data.footer.menu
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding: $gutter-G 0 0;
  background-color: $c-primary-A;
  color: $c-white;

  &-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond-to('large up') {
      flex-direction: row;
    }
  }

  &-text {
    @include respond-to('large up') {
      max-width: 79rem;
      padding-right: $gutter-E;
    }

    &-wrapper {
      margin-bottom: $gutter-E;
    }

    h2 {
      margin: 0;
      color: $c-white;
      font-size: 1.5rem;
      text-transform: uppercase;

      @include respond-to('medium up') {
        font-size: $fs-txt-A;
      }
    }

    p {
      font-size: $fs-txt-D;

      @include respond-to('medium up') {
        font-size: $fs-txt-B;
      }
    }

    a {
      font-size: $fs-txt-D;
      text-decoration: underline;

      @include respond-to('medium up') {
        font-size: $fs-txt-B;
      }
    }
  }

  &-bottom {
    padding: $gutter-D 0;
    margin-top: $gutter-C;

    @include respond-to('xs up') {
      border-top: 1px solid $c-white;
    }

    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: $gutter-B $gutter-G;

      a {
        font-size: $fs-txt-C;
        font-weight: bold;
        text-decoration: underline;
        transition: $t-default;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  &-branding {
    display: flex;
    flex-direction: column;
    gap: $gutter-D;
  }
}
</style>
