<template>
  <div class="item-donation">
    <div class="item-donation-media">
      <img
        :src="require(`@/assets/images/${img}`)"
        alt=""
        height="350"
        width="600"
      />
    </div>

    <div class="item-donation-wrapper">
      <h3 class="item-donation-title">
        <span class="text" v-html="title"></span>
        <span class="value" v-html="value"></span>
      </h3>

      <p class="item-donation-text" v-html="text"></p>

      <BtnGift :id="cta_id" :link="cta_url" />
    </div>
  </div>
</template>

<script>
import BtnGift from '@/components/ui/BtnGift.vue'

export default {
  name: 'ItemDonation',
  components: {
    BtnGift
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      img: this.content.img,
      value: this.content.value,
      title: this.content.title,
      text: this.content.text,
      cta_id: this.content.cta_id,
      cta_url: this.content.cta_url
    }
  }
}
</script>

<style lang="scss" scoped>
.item-donation {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 60rem;

  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $gutter-D $gutter-C;
    border: 1px solid $c-primary-A;
    border-radius: 0 0 $radius-D $radius-D;
  }

  &-title {
    display: flex;
    flex-direction: column;
    color: $c-primary-A;
    line-height: 1.4;

    .text {
      font-size: $fs-title-F;
      font-weight: 400;
      text-transform: uppercase;
    }

    .value {
      font-size: $fs-title-C;
      font-weight: 700;

      @include respond-to('small up') {
        font-size: $fs-title-B;
      }

      @include respond-to('large up') {
        font-size: $fs-title-A;
      }
    }
  }

  &-text {
    margin-bottom: $gutter-D;
    font-size: $fs-txt-B;

    @include respond-to('large up') {
      font-size: $fs-txt-A;
    }
  }

  .btn {
    margin-top: auto;
  }
}
</style>
