<template>
  <div class="share">
    <ul class="share-list">
      <li v-for="(network, index) in networks" :key="index">
        <ShareNetwork
          :aria-label="'partagez sur ' + network + ' (nouvel onglet)'"
          :class="`i-${network}`"
          :description="desc"
          :network="network"
          :title="title"
          :url="url"
          class="i-a11y icon-before"
          media="@/assets/images/main-bg.jpg"
          rel="norefferer noopenner"
        >
          <span aria-hidden="true" class="i-a11y-content"></span>
          <span class="visually-hidden">Partager sur {{ network }}</span>
        </ShareNetwork>
      </li>
    </ul>
  </div>
</template>

<script>
import data from '@/data/content.json'

export default {
  name: 'Share',
  data() {
    return {
      networks: ['facebook', 'twitter', 'linkedin'],
      title: data.metadata.title,
      desc: data.metadata.description,
      url: window.location.href
    }
  }
}
</script>

<style lang="scss" scoped>
.share {
  &-list {
    display: flex;
    justify-content: center;
    gap: $gutter-D;
    margin-top: $gutter-B;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: $c-bg-C;
      border-radius: $radius-A;
      color: $c-bg-E;
      transition: $t-default;

      &:hover,
      &:focus {
        background-color: $c-bg-E;
        color: $c-white;
      }
    }
  }
}

.i-a11y-content {
  font-size: 2.4rem;
}
</style>
