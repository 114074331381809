<template>
  <div class="follow">
    <p class="follow-title">Suivez-nous</p>
    <ul class="follow-list">
      <li v-for="(network, index) in networks" :key="index">
        <a
          :class="`i-${network}`"
          :href="links[index]"
          :title="'Suivez-nous sur ' + network + ' (nouvel onglet)'"
          class="i-a11y icon-before"
          target="_blank"
        >
          <span aria-hidden="true" class="i-a11y-content"></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Follow',
  data() {
    return {
      networks: ['facebook', 'instagram', 'twitter', 'youtube', 'linkedin'],
      links: [
        'https://www.facebook.com/FduSouffle',
        'https://www.instagram.com/fdusouffle/',
        'https://twitter.com/FduSouffle',
        'https://www.youtube.com/channel/UCeuYoEg0ICJiVJzCASivDZQ',
        'https://www.linkedin.com/company/2856907/admin/'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.follow {
  &-title {
    margin-bottom: $gutter-B;
    text-transform: uppercase;
    font-weight: bold;
  }

  &-list {
    display: flex;
    align-items: center;
    gap: $gutter-D;
    margin-bottom: $gutter-C;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: $c-white;
      border-radius: $radius-A;
      color: $c-bg-E;

      &:hover,
      &:focus {
        background-color: $c-bg-E;
        color: $c-white;
      }
    }
  }
}

.i-a11y-content {
  font-size: 2.4rem;
}
</style>
