<template>
  <div class="item-keyfigure">
    <h3 class="item-keyfigure-title">
      <span class="item-keyfigure-title-value" v-html="value"></span>
      <span v-html="title"></span>
    </h3>
    <p v-if="text" class="item-keyfigure-text" v-html="text"></p>
  </div>
</template>

<script>
export default {
  name: 'ItemKeyFigure',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: this.content.value,
      title: this.content.title,
      text: this.content.text
    }
  }
}
</script>

<style lang="scss" scoped>
.item-keyfigure {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 23.4rem;
  padding: $gutter-C;
  margin: 0 $gutter-A;
  border-radius: $radius-B;
  background-color: $c-white;
  text-align: center;

  @include respond-to('medium up') {
    max-width: 28rem;
    height: 25.6rem;
  }

  &::after {
    @extend %shape;
    @include absolute(bottom -1.5rem right 1.5rem);

    width: 10rem;
    height: 3.5rem;
  }

  .keyfigure-item-odd & {
    @include respond-to('medium up') {
      margin-top: 18rem;
    }

    &::after {
      @include absolute(bottom -1.5rem left 1.5rem);

      right: inherit;
      transform: scaleX(-1);

      @include respond-to('medium up') {
        @include absolute(top -1.5rem right 1.5rem);

        left: inherit;
        transform: scaleY(-1);
      }
    }
  }

  &-title {
    font-size: $fs-title-E;
    line-height: 1.3;

    @include respond-to('medium to large') {
      font-size: $fs-title-H;
    }

    &-value {
      color: $c-secondary-A;
    }
  }

  &-text {
    color: $c-primary-A;

    @include respond-to('large') {
      font-size: $fs-txt-B;
    }
  }
}
</style>
